import { useCallback, useEffect, useState } from "react";
import { Connection, PublicKey } from "@solana/web3.js";
import { Metaplex, walletAdapterIdentity } from "@metaplex-foundation/js";

import styled from "styled-components";

import { Container, } from "@mui/material";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import { SolanaMobileWalletAdapterWalletName } from "@solana-mobile/wallet-adapter-mobile";
import { useAnchorWallet, useWallet } from "@solana/wallet-adapter-react";
import { WalletDialogButton } from "@solana/wallet-adapter-material-ui";
import { CandyMachineState, getCandyMachineState, freeMint, NFT } from "./candy-machine-two";

const ConnectButton = styled(WalletDialogButton)`
  width: 100%;
  height: 60px;
  margin-top: 10px;
  margin-bottom: 5px;
  background-color: #2e363c !important;
  border: 2px solid #ffd839;
  border-color: #ffd839 !important;
  color: #ffd839 !important;
  font-size: 1em !important;
  font-family: "Press+Start+2P" normal;
  font-weight: normal;
`;

export const MintButton = styled(Button)`
  width: 100%;
  height: 60px;
  margin-top: 10px;
  margin-bottom: 5px;
  background-color: #2e363c !important;
  border: 2px solid #ffd839;
  color: #ffff !important;
  font-size: 1em !important;
  font-family: "Press Start 2P" normal;
  font-weight: normal;
`;

export interface HomeProps {
  candyMachineTwoId: string;
  connectionTwo: Connection;
  txTimeout: number;
  rpcHost: string;
  network: WalletAdapterNetwork;
  error: string | undefined;
}

const Home2 = (props: HomeProps) => {
  const anchorWallet = useAnchorWallet();
  const { connect, connected, publicKey, wallet } = useWallet();
  const [candyMachine, setCandyMachine] = useState<CandyMachineState>();
  const [nft, setNft] = useState<NFT>();
  const [isUserMinting, setIsUserMinting] = useState(false);
  const [error, setError] = useState("");
  
  const metaplex = new Metaplex(props.connectionTwo);

  const refreshCandyMachineState = useCallback(
    async () => {
      if (!publicKey) {
        return;
      }

      const candyMachine = await getCandyMachineState(metaplex, new PublicKey(props.candyMachineTwoId));
      setCandyMachine(candyMachine);
    },
    [anchorWallet, props.candyMachineTwoId, props.rpcHost]
  );

  const getFreeMintButtonContent = () => {
    if (!candyMachine) {
      return "Loading...";
    }

    if (isUserMinting) {
      return "Minting in progress..";
    } else if (candyMachine.itemsRemaining === 0) {
      return "Sold out";
    } else {
      return "FREE";
    }
  };

  const freeMintButtonClicked = async () => {
    setIsUserMinting(true);

    metaplex.use(walletAdapterIdentity(wallet!.adapter));
    const nft = await freeMint(metaplex, candyMachine!);

    if (nft) {
      setNft(nft);
    } else {
      setError("You only get 1 freebie!");
    }
    
    setIsUserMinting(false);
    refreshCandyMachineState();
  }

  useEffect(() => {
    refreshCandyMachineState();
  }, [
    anchorWallet,
    props.candyMachineTwoId,
    props.connectionTwo,
    refreshCandyMachineState,
  ]);

  useEffect(() => {
    (function loop() {
      setTimeout(() => {
        refreshCandyMachineState();
        loop();
      }, 20000);
    })();
  }, [refreshCandyMachineState]);

  return (
    <Container style={{ marginTop: 20 }}>
      <Container maxWidth="xs" style={{ position: 'relative' }}> 
        <Paper
          style={{ padding: 24, background: '#3a434a', borderColor: '#ffd839', border: '3px solid #ffd839', borderRadius: 6 }}
        >
          {error || props.error ? (
            <Typography
            variant="h6"
            color="textPrimary"
            style={{
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            {error || props.error}
          </Typography>
          ) : undefined}
          {!connected ? (
            <ConnectButton
              onClick={(e) => {
                if (
                  wallet?.adapter.name === SolanaMobileWalletAdapterWalletName
                ) {
                  connect();
                  e.preventDefault();
                }
              }}
            >
              <Typography variant="body2" color="textSecondary" style={{
                      fontSize: "16px",
                      fontWeight: "bold",
                      color: "white",
                      textAlign: "center",
                    }}>
                Connect Wallet
              </Typography>
            </ConnectButton>
          ) : (
            <>
              <Grid
                container
                direction="row"
                justifyContent="center"
                wrap="nowrap"
              >
                <Grid item xs={3}>
                  <Typography variant="body2" color="#ffd839" style={{
                      textAlign: "center",
                    }}>
                    Remaining
                  </Typography>
                  <Typography
                    variant="h6"
                    color="textPrimary"
                    style={{
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    {`${candyMachine ? candyMachine.itemsRemaining : "Loading.."}`}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography variant="body2" color="#ffd839">
                        Price
                    </Typography>
                    <Typography
                      variant="h6"
                      color="#ffd839"
                      style={{ fontWeight: "bold" }}
                    >
                      free fiddy
                    </Typography>
                  </Grid>
              </Grid>
              <Grid
                container
                direction="row"
                justifyContent="center"
                wrap="nowrap"
              >
                <MintButton onClick={async () => await freeMintButtonClicked()} disabled={isUserMinting || candyMachine?.itemsRemaining === 0}>
                  <Typography variant="body2" color="textSecondary" style={{
                      fontSize: "16px",
                      fontWeight: "bold",
                      color: "white",
                      textAlign: "center",
                    }}>
                    { getFreeMintButtonContent() }
                  </Typography>
                </MintButton>
              </Grid>
              
            </>
          )}
        </Paper>
      </Container>
    </Container>
  );
};

export default Home2;

