import styled from "styled-components";
import { WalletMultiButton } from "@solana/wallet-adapter-material-ui";

const Container = styled("div")`
    width: 100vw;
    height: auto;
    background-color: null;

    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    position: relative;
`

const Cmbubble = styled("div")`
    width: auto;
    height: auto;
    cursor: pointer;

    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    position: relative;
`

const Donnie = styled("div")`
    width: auto;
    height: auto;
    cursor: pointer;

    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    position: relative;
`

const Sphones = styled("div")`
    width: auto;
    height: auto;
    cursor: pointer;

    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    position: relative;
`

const Argg = styled("div")`
    width: auto;
    height: auto;
    cursor: pointer;

    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    position: relative;

    @media (max-width: 448px) {
      display: none !important;
  }
`


const Link = styled("a")`
    font-size: 1.5rem;
    margin: 1rem;
    cursor: pointer;
    color: initial;
    text-decoration: none;

    @media (max-width: 700px) {
        position: initial;
    }
`
const Wall = styled("div")`
    font-size: 1.5rem;
    margin: 1rem;
    margin-bottom: 2px
    cursor: pointer;
    color: initial;
    text-decoration: none;

    position: absolute;
    right: 5%;

    @media (max-width: 700px) {
        position: initial;
    }
`

const Navbar = () => {
    return (
        <Container>

            <Cmbubble>
                <Link href="https://donsnft.com/home">
                  <img
                    style={{
                      maxHeight: "50px",
                    }}
                    src="/images/click me.png"
                    alt="talk bubble"
                  />
                </Link>
            </Cmbubble>
            <Donnie>
                <Link href="https://donsnft.com/home">
                  <img
                    style={{
                      maxHeight: "50px",
                    }}
                    src="/images/donnie.png"
                    alt="super donnie"
                  />
                </Link>
            </Donnie>
            <Sphones>
                <Link href="https://www.piratebroadcast.xyz/shows">
                  <img
                    style={{
                      maxHeight: "50px",
                    }}
                    src="/images/SkullPhones yellow.png"
                    alt="skull phones"
                  />
                </Link>
            </Sphones>
            <Argg>
                <Link href="https://www.piratebroadcast.xyz/shows">
                  <img
                    style={{
                      maxHeight: "50px",
                    }}
                    src="/images/ARRR.png"
                    alt="argg"
                  />
                </Link>
            </Argg>
            <Wall>
            <WalletMultiButton />
            </Wall>
        </Container>
    )
}

export default Navbar;