import { Metaplex, getMerkleProof, token, sol, getMerkleRoot } from "@metaplex-foundation/js";
import { PublicKey } from "@solana/web3.js";

export interface HomeProps {
    candyMachineId: string;
    ownerWallet: string;
    donnyBucks: string;
    synthCity: string;
  }

export interface CandyMachineState {
  rawCandyMachine: any;

  address: PublicKey;

  authorityAddress: string;
  mintAuthorityAddress: string;
  collectionMintAddress: string;
  symbol: string;
  sellerFeeBasisPoints: number;
  isMutable: boolean
  maxEditionSupply: string;

  creators: {
    address: string;
    percentageShare: number;
  }[];

  items: {
    index: number;
    minted: boolean;
    name: string;
    uri: string;
  }[];

  itemsAvailable: number;
  itemsMinted: number;
  itemsRemaining: number;
  itemsLoaded: number;
  isFullyLoaded: boolean;
}

export interface NFT {
  rawNft: any;

  updateAuthorityAddress: PublicKey;

  name: string;
  symbol: string;
  description: string;
  uri: string;
  imageUri: string;
  attributes: {
    trait_type: string;
    value: string;
  }[];
}

const tokenMint = new PublicKey("EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v");

const tokenAccount = new PublicKey("6qWKrmF8MxjeMpSXGEbzj2qgjNk5Ap7cbWC2dh1G3a7R");


export const getCandyMachineState = async (
  metaplex: Metaplex,
  candyMachineId: PublicKey,
  ): Promise<CandyMachineState> => {
  const candyMachine: any = await metaplex
    .candyMachines()
    .findByAddress({ address: candyMachineId });

  const candyMachineState: CandyMachineState = {
    rawCandyMachine: candyMachine,

    address: new PublicKey(candyMachine.address),
  
    authorityAddress: candyMachine.authorityAddress,
    mintAuthorityAddress: candyMachine.mintAuthorityAddress,
    collectionMintAddress: candyMachine.collectionMintAddress,
    symbol: candyMachine.symbol,
    sellerFeeBasisPoints: candyMachine.sellerFeeBasisPoints,
    isMutable: candyMachine.isMutable,
    maxEditionSupply: candyMachine.maxEditionSupply,
  
    creators: candyMachine.creators.map((creator: any) => {
      return {
        address: new PublicKey(creator.address),
        percentageShare: creator.percentageShare,
      }
    }),

    items: candyMachine.items.map((item: any) => {
      return {
        index: item.index,
        minted: item.minted,
        name: item.name,
        uri: item.uri,
      }
    }),
  
    itemsAvailable: +candyMachine.itemsAvailable,
    itemsMinted: +candyMachine.itemsMinted,
    itemsRemaining: +candyMachine.itemsRemaining,
    itemsLoaded: candyMachine.itemsLoaded,
    isFullyLoaded: candyMachine.isFullyLoaded,
  };

  return candyMachineState;
};


export const payMint = async (
  metaplex: Metaplex,
  candyMachineState: CandyMachineState,
) => {
  try {
    const result: any = await metaplex.candyMachines().mint({
      candyMachine: candyMachineState.rawCandyMachine,
      collectionUpdateAuthority: new PublicKey("DonZerpxGLnaTJYaUKY2zLAoRGTejyHsxMaJRGj3DttF"),
  group: "pay",
      guards: {
        tokenPayment: {
          amount: token(100000000),
          mint: tokenMint,
          destinationAta: tokenAccount,
        },
      },
  })

    console.log(result);

    return {
      rawNft: result.nft,
      updateAuthorityAddress: new PublicKey(result.nft.updateAuthorityAddress),
      name: result.nft.name,
      symbol: result.nft.symbol,
      description: result.nft.json.description,
      imageUri: result.nft.json.image,
      uri: result.nft.uri,
      attributes: result.nft.json.attributes.map((attribute: any) => {
        return {
          trait_type: attribute.trait_type,
          value: attribute.value,
        }
      }),
    }
  } catch(e) {
    console.log(e);
    return null;
  }
}

export const frenMint = async (
    metaplex: Metaplex,
    candyMachineState: CandyMachineState,
  ) => {
    try {
      const result: any = await metaplex.candyMachines().mint({
        candyMachine: candyMachineState.rawCandyMachine,
        collectionUpdateAuthority: (new PublicKey("DonZerpxGLnaTJYaUKY2zLAoRGTejyHsxMaJRGj3DttF")),
        group: "fren",
            guards: {
              nftGate: {
                  mint: (new PublicKey("8SZNPgKzRHqMTebfs5196vKx3JZ8doVgqgBop2pHuqAD"))
                },
            },
        })
  
      console.log(result);
  
      return {
        rawNft: result.nft,
        updateAuthorityAddress: new PublicKey(result.nft.updateAuthorityAddress),
        name: result.nft.name,
        symbol: result.nft.symbol,
        description: result.nft.json.description,
        imageUri: result.nft.json.image,
        uri: result.nft.uri,
        attributes: result.nft.json.attributes.map((attribute: any) => {
          return {
            trait_type: attribute.trait_type,
            value: attribute.value,
          }
        }),
      }
    } catch(e) {
      console.log(e);
      return null;
    }
  }

  export const crewMint = async (
    metaplex: Metaplex,
    candyMachineState: CandyMachineState,
  ) => {
    try {
      const result: any = await metaplex.candyMachines().mint({
        candyMachine: candyMachineState.rawCandyMachine,
        collectionUpdateAuthority: (new PublicKey("DonZerpxGLnaTJYaUKY2zLAoRGTejyHsxMaJRGj3DttF")),
  group: "crew",
      guards: {
        nftGate: {
            mint: (new PublicKey("GgHGjozMVRMTRS7mXrKARJX22dYK3webYHawjLGoPsi7")),
          },
      },
  })
  
      console.log(result);
  
      return {
        rawNft: result.nft,
        updateAuthorityAddress: new PublicKey(result.nft.updateAuthorityAddress),
        name: result.nft.name,
        symbol: result.nft.symbol,
        description: result.nft.json.description,
        imageUri: result.nft.json.image,
        uri: result.nft.uri,
        attributes: result.nft.json.attributes.map((attribute: any) => {
          return {
            trait_type: attribute.trait_type,
            value: attribute.value,
          }
        }),
      }
    } catch(e) {
      console.log(e);
      return null;
    }
  }
  
  export const allowMint = async (
    metaplex: Metaplex,
    candyMachineState: CandyMachineState,
    
  ) => {
    
    try {
      const result: any = await metaplex.candyMachines().mint({
        candyMachine: candyMachineState.rawCandyMachine,
        collectionUpdateAuthority: (new PublicKey("DonZerpxGLnaTJYaUKY2zLAoRGTejyHsxMaJRGj3DttF")),
      group: "alist",
      guards: {
        allowList: { merkleRoot },
      mintLimit: {
          id: 1,
          limit: 40,
       },
      solPayment: { amount: sol(0), destination: "DonZerpxGLnaTJYaUKY2zLAoRGTejyHsxMaJRGj3DttF" },
      },
  })
  
      console.log(result);
  
      return {
        rawNft: result.nft,
        updateAuthorityAddress: new PublicKey(result.nft.updateAuthorityAddress),
        name: result.nft.name,
        symbol: result.nft.symbol,
        description: result.nft.json.description,
        imageUri: result.nft.json.image,
        uri: result.nft.uri,
        attributes: result.nft.json.attributes.map((attribute: any) => {
          return {
            trait_type: attribute.trait_type,
            value: attribute.value,
          }
        }),
      }
    } catch(e) {
      console.log(e);
      return null;
    }
  }

  export const merkle = async (
    metaplex: Metaplex,
    candyMachineState: CandyMachineState,
  ) => {
  

      const mintingWallet = metaplex.identity().publicKey.toBase58();

      await metaplex.candyMachines().callGuardRoute({
        candyMachine: candyMachineState.rawCandyMachine,
        group: "alist",
        guard: 'allowList',
        settings: {
          path: 'proof',
          merkleProof: getMerkleProof(allowList, mintingWallet),
        },
      });

      console.log();
  };

  const allowList = [
    '4wyTg9U7xfLL3h8LEcSZFf8LwFXUhqPDrEawrPwxzWTx',
    'DonZerpxGLnaTJYaUKY2zLAoRGTejyHsxMaJRGj3DttF',
    'Shop1Vz2XLcJ1yKraUvzkiZNjE6hGWyBaK5gJzo1Vcw',
    'AnQHkH2z52J5XrnSQ2KEZ7TMkn2yUwfpM8iCBXTRrjBs',
    'EQAv3n5BgYB2dwDaFjxsT7By7mhEtvGHzAN2Uu5ngMRp',
    'J4buDR9KVnZtijYnx49dnJcTYEjXu79Y781Jut1tEY4Y',
    "9ZRSofvY4CAKgiUo8BEW1r3KBi4eBn3U3pD5aqNx1Uqz",
    "Gg3356rA9X2Mu2PyzexJN9FMySpunQJGe2mFtvn5DQa2",
    "GJnCpmJcCjxD2GqjQKZBoYvNjnBMtPcUvDoxisThZvdT",
    "87Kbn36daLAfAAjZ9NvHeDXW8GFYUCYJTUJkNZHr3oXW",
    "3FdTPuiJG1atYWMRqukPDmdoeatamMbgFTQBUxik7Wiv",
    "Gj1iBD2uVaFhP3BG2nKiZ7F3LoU4iUbzGMYp42ESmu5W",
    "4RsmMMrz2E9vwhLZz79sKdbPnV7HyeZwUECfwux1x7xn",
    "6SNaZM38E4kcMSZk5dz7XDQLzVuMgbvnvGYMTLRcFv1u",
    "ELKAAQCZ76jxbypjqB5expG1EW3SrWU5FKKRwczwmHnp",
    "5B7171WKP8hdP7pyAHUhhNH5g5N56Y2VWCLYNtka29YL",
    "929J7nPgcoTKAvXKrYuYi4YDVyBcm1vZZeqc6Z3QDt4X"
  ];
  
   
  const merkleRoot = getMerkleRoot(allowList);