import styled from "styled-components";
import "./globals.css";

import { Container, } from "@mui/material";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

const Tips = () => {

  return (
    <Container style={{ marginTop: 20 }}>
      <Container maxWidth="xs" style={{ position: 'relative' }}> 
        <Paper
          style={{ padding: 24, background: '#3a434a', borderColor: '#ffd839', border: '3px solid #ffd839', borderRadius: 6 }}
        >
          <Typography
          variant="h6"
          color="ffd839"
          style={{
            fontWeight: "bold",
            textAlign: "center",
            fontFamily: "Press Start 2P"
          }}
          >
            MINT TIPS:
          </Typography>
          <Typography variant="body1" color="#ffd839" style={{
                      textAlign: "start", fontFamily: "Press Start 2P"
                    }}>
                    Free Mint:
          </Typography>
          <Typography variant="body2" color="textPrimary" style={{
                      textAlign: "start", fontFamily: "Press Start 2P"
                    }}>
                    We'll have a coupon day or something... scroll down for a free mint that is pretty rad but will potentially be used a coupon to buy 2 SaibaKongJR tokens at 50% off. Basically a BOGO.
          </Typography>
          <Typography variant="body1" color="#ffd839" style={{
                      textAlign: "start", fontFamily: "Press Start 2P"
                    }}>
                    USDC:
          </Typography>
          <Typography variant="body2" color="textPrimary" style={{
                      textAlign: "start", fontFamily: "Press Start 2P"
                    }}>
                    To mint you need USDC in your sol wallet of choice. We prefer Phantom. You can easily exchange SOL for USDC in Phantom wallet. If you get a warning with 
          </Typography>
          <Typography variant="body2" color="#ffd839" style={{
                      textAlign: "start", fontFamily: "Press Start 2P"
                    }}> 
                    yellow
          </Typography>
          <Typography variant="body2" color="textPrimary" style={{
                      textAlign: "start", fontFamily: "Press Start 2P"
                    }}>
           text in phanton wallet that reads "unable to simulate", you don't have enough USDC in your wallet. If it is reads transaction expired, then you are probably not on mainnet. What you building? let us know in discord. 
          </Typography>
          <Typography variant="body1" color="#ffd839" style={{
                      textAlign: "start", fontFamily: "Press Start 2P"
                    }}> 
                    PROOF && CAR
          </Typography>
          <Typography variant="body2" color="textPrimary" style={{
                      textAlign: "start", fontFamily: "Press Start 2P"
                    }}>
           We plan to do an allow list sale using the freebie below. When/If that day comes, click/tap proof to verify you are on the allow list before using the "car" button as the discount price mint button.   
          </Typography>
          <Typography variant="body1" color="#ffd839" style={{
                      textAlign: "start", fontFamily: "Press Start 2P"
                    }}> 
                    CROSSMINT BUTTONS
          </Typography>
          <Typography variant="body2" color="textPrimary" style={{
                      textAlign: "start", fontFamily: "Press Start 2P"
                    }}>
           You can buy up to 5 NFTs per transaction using Crossmint. Pay using Credit Card, SOL, or ETH. A pop up window will guide you through the steps. If paying with card we prefer using your favorite web2 browser such as Chrome over a web3 browser. If paying with SOL use the browser in your Solana app if on mobile for example Phantom Wallet. If on desktop make sure your favorite SOL wallet extension is installed. Do the same for ETH. If on mobile use the browser in your favorite EVM app such as Metamask or make sure that a compatible ETH wallet extension is installed to your desktop browser. 
          </Typography>
        </Paper>
      </Container>
    </Container>
  );
};

export default Tips;

