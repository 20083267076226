import './App.css';
import "./globals.css";
import { useMemo } from "react";

import Home from "./Home";
import Home2 from "./Home2";
import Tips from './Tips';
import { Connection } from "@solana/web3.js";
import Navbar from './components/Navbar';

import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";

import { ConnectionProvider, WalletProvider} from "@solana/wallet-adapter-react";
import { WalletDialogProvider } from "@solana/wallet-adapter-material-ui";

import { createTheme, ThemeProvider } from "@mui/material";
import { PhantomWalletAdapter } from "@solana/wallet-adapter-phantom";
import { SlopeWalletAdapter } from "@solana/wallet-adapter-slope";
import { SolflareWalletAdapter } from "@solana/wallet-adapter-solflare";
import { SolletWalletAdapter, SolletExtensionWalletAdapter } from "@solana/wallet-adapter-sollet";

const theme = createTheme({
  palette: {
    mode: "dark",
  },
});

let error: string | undefined = undefined;

const getCandyMachineId = (): string => {
  if (!process.env.REACT_APP_CANDY_MACHINE_ID) {
    error = "Missing Candy Machine ID. Please add it as REACT_APP_CANDY_MACHINE_ID value in .env file";
    throw new Error(error);
  }
  return process.env.REACT_APP_CANDY_MACHINE_ID as string;
};

const getCandyMachineTwoId = (): string => {
  if (!process.env.REACT_APP_CANDY_MACHINE_TWO_ID) {
    error = "Missing Candy Machine ID. Please add it as REACT_APP_CANDY_MACHINE_ID value in .env file";
    throw new Error(error);
  }
  return process.env.REACT_APP_CANDY_MACHINE_TWO_ID as string;
};

const getNetwork = (): string => {
  if (!process.env.REACT_APP_SOLANA_NETWORK) {
    error = "Missing Solana Network. Please add it as REACT_APP_SOLANA_NETWORK value in .env file";
    throw new Error(error);
  }
  return process.env.REACT_APP_SOLANA_NETWORK as string;
};

const getRpcHost = (): string => {
  if (!process.env.REACT_APP_SOLANA_RPC_HOST) {
    error = "Missing RPC Host. Please add it as REACT_APP_SOLANA_RPC_HOST value in .env file";
    throw new Error(error);
  }
  return process.env.REACT_APP_SOLANA_RPC_HOST as string;
};



const App = () => {
  let candyMachineId: string = "";
  let candyMachineTwoId: string = "";
  let connection: Connection | null = null;
  let connectionTwo: Connection | null = null;
  let rpcHost: string = "";
  let endpoint: string | null = "";
  let network: WalletAdapterNetwork = "mainnet" as WalletAdapterNetwork;
  let wallets: any = [];
  let isError = false;

  try {
    isError = false;
    candyMachineId = getCandyMachineId();
    candyMachineTwoId = getCandyMachineTwoId();
    network = getNetwork() as WalletAdapterNetwork;
    rpcHost = getRpcHost();
  
    connection = new Connection(rpcHost);
    connectionTwo = new Connection(rpcHost);
    
  } catch (e) {
    isError = true;
    console.error(e);
  }

  endpoint = useMemo(() => {
    return isError ? null : rpcHost;
  }, [isError]);
  
  wallets = useMemo(
    () => {
      return isError ? [] :  [
        new PhantomWalletAdapter(),
        new SolflareWalletAdapter({ network }),
        new SlopeWalletAdapter(),
        new SolletWalletAdapter({ network }),
        new SolletExtensionWalletAdapter({ network }),
      ]
    },
    [isError]
  );

  return (
    <ThemeProvider theme={theme}>
      <ConnectionProvider endpoint={endpoint as string}>
        <WalletProvider wallets={wallets} autoConnect>
          <WalletDialogProvider>
          <Navbar />
            <Home
              candyMachineId={candyMachineId}
              connection={connection as Connection}
              txTimeout={500000}
              rpcHost={rpcHost}
              network={network}
              error={error}
            />
            <Tips />
            <Home2
              candyMachineTwoId={candyMachineTwoId}
              connectionTwo={connectionTwo as Connection}
              txTimeout={500000}
              rpcHost={rpcHost}
              network={network}
              error={error}
            />
          </WalletDialogProvider>
        </WalletProvider>
      </ConnectionProvider>
    </ThemeProvider>
  )
};

export default App;
